import React, { useState, useEffect } from 'react';
import './App.css';
import Geocode from "react-geocode";
import ReactMapboxGl, { Layer, Feature, GeoJSONLayer} from "react-mapbox-gl";
import anunciogeojson from './anuncio'
import useForm from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
/* import ButtonBase from '@material-ui/core/ButtonBase'; */
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { Twitter, Whatsapp, Facebook } from 'react-social-sharing'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'justify',
    position: 'absolute',
    zIndex: 1,
    flexGrow: 1,
    padding: theme.spacing(1, 1),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: '0 auto',
    maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  fab: {
    marginTop: theme.spacing(1),
  },
}));

const Map = ReactMapboxGl({
  /* minZoom: 8, */
  /* maxZoom: 15, */
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
});

const APIKEY = process.env.REACT_APP_GOOGLE_SET_API_KEY
const LANG = process.env.REACT_APP_GOOGLE_SET_LANGUAGE
const REGION = process.env.REACT_APP_GOOGLE_SET_REGION



Geocode.setApiKey(APIKEY);
// set response language. Defaults to english.
Geocode.setLanguage(LANG);
// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion(REGION);
// Enable or disable logs. Its optional.
Geocode.enableDebug();

const geojsonStyles = {
  fillPaint:{
    "fill-color": "rgba(255, 155, 255, 0.4)",
    "fill-outline-color": "red"
  },
  lineLayout: {
    "line-join": "round",
    "line-cap": "round"
  },
  /* linePaint: {
   *   "line-color": "#ff11ff",
   *   "line-width": 4,
   *   "line-opacity": 1
   * }, */
  symbolLayout: {
    "text-field": "{text}",
    "symbol-placement": "line",
    "text-rotation-alignment": "map",
    "text-size": {
      base: 1,
      stops: [[9, 9], [14, 12]]
    }
  },
  symbolPaint: {
    "text-color": "rgba(0, 0, 0, 1)",
    "text-halo-color": "rgba(255, 255, 255, 1)",
    "text-halo-width": 2
  }
};

function App() {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm(); // initialise the hook
  const [address, setAddress] = useState("");
  const onSubmit = data => {
    // console.log(data)
    setAddress(`Colombia, Medellín, ${data['address']}`);
  };
  // Get latidude & longitude from address.
  const [location, setLocation] = useState([-75.5975626, 6.2634013]);

  useEffect(() => {
    if(address !== "") {
      Geocode.fromAddress(address).then(
        response => {
          const { lng, lat } = response.results[0].geometry.location;
          setLocation([ lng, lat ])
          // console.log(lat, lng);
        },
        error => {
          console.error(error);
        }
      );
    }
  },[address])

  return (
    <div className="App">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography component="div">
              <span role="img" aria-label="alerta">🚨</span> Su propiedad puede estar en riesgo <span role="img" aria-label="alerta">🚨</span> si se encuentra dentro del polígono rojo puede ser expropiada por el proyecto "Corredor vial de la avenida 80"
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <TextField
                error={errors.address}
                name="address"
                id="standard-helperText"
                label="Escriba su dirección"
                className={classes.textField}
                helperText={errors.address && 'Es necesaria la dirección o un punto de referencia para ubicar su predio.'}
                inputRef={register({ required: true })}
                variant="outlined"
                margin="dense"
              />
              <Fab type="submit" color="secondary" size="small" aria-label="edit" className={classes.fab}>
                <SearchIcon/>
              </Fab>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs>
                Comparte con tus amigos <span role="img" aria-label="abajo">↙</span>
                <Typography component="p">
                  <Facebook solidcircle small message="Mi propiedad está en riesgo por el #Tranviadela80 #MedellínSeTransformó @vecinosdela80" link="https://www.vecinosdela80.org"/>
                  <Twitter solidcircle small message="Mi propiedad está en riesgo por el #Tranviadela80 #MedellínSeTransformó @vecinosdela80" link="https://www.vecinosdela80.org"/>
                  <Whatsapp solidcircle small message="Mi propiedad está en riesgo por el #Tranviadela80 #MedellínSeTransformó @vecinosdela80" link="https://www.vecinosdela80.org"/>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <a href="https://www.vecinosdela80.org/info/">Le explicamos ¿por qué?</a>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
      <Map
        style="mapbox://styles/mapbox/streets-v9" // eslint-disable-line
        containerStyle={{
          height: "100vh",
          width: "100vw"
        }}
        bearing={[-85]}
        pitch={[45]}
        zoom={[13]}
        center={location}
        maxBounds={[[-75.6694,6.1361],[-75.4713,6.3638]]}
      >
        <GeoJSONLayer {...geojsonStyles} data={anunciogeojson} />
        <Layer
          type="circle"
          id="position-marker"
          paint={{
            'circle-stroke-width': 4,
            'circle-radius': 8,
            'circle-blur': 0.15,
            'circle-color': 'red',
            'circle-stroke-color': 'yellow',
          }}>
          <Feature coordinates={location}/>
        </Layer>
      </Map>
    </div>
  );
}

export default App;
